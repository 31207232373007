"use client"

import ProtectedLayout from "@/components/layout/protected-layout"
import type { LayoutProps } from "@/types"

export default function ProtectedRouteLayout({
  children,
}: Readonly<LayoutProps>) {
  return <ProtectedLayout>{children}</ProtectedLayout>
} 
