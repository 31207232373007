import { cn } from "@/lib/utils";
export default function Warn ({ className = '', stroke = '' }) {
  return (
    <svg  className={cn("stroke-header-icons",className)} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1515_47358)">
    <path d="M7.9943 15.6497C11.6762 15.6497 14.661 12.665 14.661 8.98307C14.661 5.30117 11.6762 2.31641 7.9943 2.31641C4.3124 2.31641 1.32764 5.30117 1.32764 8.98307C1.32764 12.665 4.3124 15.6497 7.9943 15.6497Z" fill="#FFFCC1" stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.99414 6.31641V8.98307" stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.99414 11.6484H8.00081" stroke="#FFC700" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_1515_47358">
    <rect width="16" height="16" fill="white" transform="translate(-0.00585938 0.982422)"/>
    </clipPath>
    </defs>
    </svg> 
  );
}