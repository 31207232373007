import { cn } from "@/lib/utils";
 
export default function CaretRight ({ className = '', stroke = '' }) {
  return (
    <svg className={cn(className)} width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.98427 14.9822C12.298 14.9845 14.9862 12.3001 14.9885 8.98643C14.9908 5.67272 12.3064 2.98455 8.99267 2.98223C5.67897 2.97991 2.9908 5.66432 2.98848 8.97803C2.98616 12.2917 5.67057 14.9799 8.98427 14.9822Z" fill="#32BF78" stroke="#18B169" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.7397 7.48408L8.07061 10.9815L6.23852 9.23022" stroke="#0C8F54" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    
  );
}