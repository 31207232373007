import { cn } from "@/lib/utils";

export default function Template ({ className = '', stroke = '' }) {
  return (
    <svg className={cn(className)}
      width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.20867 1.91475L2.79788 2.7969L1.91572 7.2077C1.9016 7.27832 1.90513 7.35134 1.926 7.42028C1.94687 7.48921 1.98444 7.55193 2.03537 7.60286L7.74763 13.3151C7.78825 13.3557 7.83648 13.388 7.88956 13.41C7.94264 13.4319 7.99953 13.4433 8.05699 13.4433C8.11444 13.4433 8.17133 13.4319 8.22441 13.41C8.27749 13.388 8.32572 13.3557 8.36635 13.3151L13.3161 8.36537C13.3567 8.32474 13.3889 8.27652 13.4109 8.22344C13.4329 8.17036 13.4442 8.11346 13.4442 8.05601C13.4442 7.99856 13.4329 7.94167 13.4109 7.88859C13.3889 7.83551 13.3567 7.78728 13.3161 7.74665L7.60383 2.03439C7.5529 1.98346 7.49019 1.94589 7.42125 1.92502C7.35232 1.90415 7.2793 1.90062 7.20867 1.91475V1.91475Z"
      stroke={stroke} strokeLinecap="round" strokeLinejoin="round"
      />
      <path d="M5.09375 5.75C5.45619 5.75 5.75 5.45619 5.75 5.09375C5.75 4.73131 5.45619 4.4375 5.09375 4.4375C4.73131 4.4375 4.4375 4.73131 4.4375 5.09375C4.4375 5.45619 4.73131 5.75 5.09375 5.75Z" fill="#1454CC"/>
    </svg>
  )
};