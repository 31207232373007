
"use client"

import React from 'react'
import AnotomyPanel from '@/components/Anotomy/anotomy-selection-bar'

  const AnotomyLayout = () => {

  return (
        <AnotomyPanel />
  );
};

export default AnotomyLayout;