import React from 'react';
import { createPortal } from 'react-dom';
import { AuthContextType, useAuth } from '@/context/auth-provider';

interface LogoutPopupProps {
  onClose: () => void;
}

const LogoutPopup: React.FC<LogoutPopupProps> = ({ onClose }) => {
  const { signOut }: AuthContextType = useAuth();

  const handleCancel = () => {
    onClose();
  };

  const handleProceed = async () => {
    try {
      await signOut();
      onClose();
    } catch (error) {
      // Handle error if needed
      console.error("Logout failed:", error);
    }
  };

  return createPortal(
    <div className="fixed inset-0 bg-backgroundBlur bg-opacity-20 backdrop-blur-sm flex justify-center items-center z-50">
      <div className="bg-user-cardBackground rounded-lg shadow-2xl   w-[410px]  h-[270px]">
        {/* Heading */}
        <div className="text-center">
        <h2 className="text-xl font-semibold mb-2 text-switchTab-textColor text-center pt-2 text- text-darkText">Logout</h2>        
          <div className="h-px bg-radio-displayBorder my-3" />
        </div>

        {/* Content */}
        <div className="p-6">
        <p className="font-bold mb-5 text-left text-switchTab-textColor">Are you sure you want to logout?</p>
        <p className="mb-2 text-left text-switchTab-textColor font-semi-bold">Your session will end, and you will need to login again to continue.</p>
        </div>

        {/* Buttons */}
        <div className="flex justify-end mb-4 space-x-4 mr-4 ">        
                  <button
      className="border-2 border-user-dropText text-user-dropText px-4 py-2 rounded-md text-sm w-24"
      onClick={handleCancel}
    >
      Cancel
    </button>
    <button
      className="bg-user-button text-white px-3 py-2 rounded-md text-sm w-24"
      onClick={handleProceed}
    >
      Proceed
    </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default LogoutPopup;