'use client';

import React, { FC, useEffect, useState } from 'react';
import { X } from 'lucide-react';
import Bell from '@/components/svg/bell'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../components/ui/popover'; 
import { Notification, NotificationsDialogProps } from '../../types/notifications';
import { apiService } from '../../utlity/api-service';
import { API_LIST } from '../../utlity/api-list';
import { momentFormat } from '../../utlity/format';
import socketService from '../../utlity/socket-service';
import { HeaderIcons, ChatMessage } from '../../types/chat';
import { useExamStore } from '@/store/use-exam-store';
import { useParams } from 'next/navigation';

const NotificationsDialog: FC<NotificationsDialogProps> = ({ 
  open, 
  onOpenChange,
  activeIcon,
  setActiveIcon
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unRead, setUnRead] = useState<boolean>(false);  
  const [initialNotificationId, setInitialNotificationId] = useState<string | null>(null);
  const { setIsChatOpen, setActiveComment } = useExamStore();

  const { examId } = useParams<{ examId: string }>() ?? {};
  const examNumber = examId ? Number(examId) : null;

  useEffect(() => {
    connectSocket();
  }, []);

  const fetchNotifications = async () => {
    try {
      const response: any = await apiService.get(API_LIST.GET_NOTIFICATIONS);
      setNotifications(response.data);
      
      if (Array.isArray(response.data)) {
        const ids = response.data.map((notification: { id: string }) => notification.id);
        setUnRead(response.data.some((notification: Notification) => !notification.isRead));        
        if (ids.length > 0) {
          setInitialNotificationId(ids[0]);
        }
      }
    } catch {
      setNotifications([]);
    }
  };
 
  const clearNotifications = async () => {
    try {
      if (initialNotificationId) {
        markAsRead(initialNotificationId);
      }
      const response = await apiService.post<any>(API_LIST.CLEAR_NOTIFICATIONS);
      const { data } = response || [];
      setNotifications(data as Notification[]);
    } catch {
    }
  };

  const markAsRead = async (notificationId: string) => {
    try {
      const payload = {
        notificationId: notificationId
      };
      await apiService.post(API_LIST.UPDATE_NOTIFICATION, payload);
    } catch {
    }
  };

  const handleIconClick = (iconName: string) => {
    if (activeIcon === iconName) {
      onOpenChange(!open);
      setActiveIcon(null)
      setUnRead(false)
      if (initialNotificationId) {
        markAsRead(initialNotificationId);
      }
    } else {
      setIsChatOpen(false)
      setActiveComment(null)
      setActiveIcon(iconName)
      fetchNotifications();
      onOpenChange(!open);
    }
  }

  const handlePopoverChange = (isOpen: boolean) => {
    onOpenChange(isOpen);
    setIsChatOpen(false)
    setActiveComment(null)
    if (!isOpen) {
      setActiveIcon('');
    }
  };

  const handleClose = () => {
    if (initialNotificationId) {
      markAsRead(initialNotificationId);
    }
    onOpenChange(false)
    setActiveIcon('')
    setUnRead(false)
    setIsChatOpen(false)
    setActiveComment(null)
  }

  const handleButtonClick = (notification: Notification, e: React.MouseEvent<HTMLButtonElement>) => {
    setIsChatOpen(true)
    const {
      id,
      time: createdAt,
      coordinates = '',
      commentId = 0,
      frameId = 0,
      diaSeqId = 0,
      frameType = 0,
      diagnosticViewId = 0,
      anatomyId = 0,
      isResolved = false,
      isSender = false,
      commentContent = '',
    } = notification ?? {};

    setActiveComment({
      id,
      createdAt,
      coordinates,
      commentId,
      frameId,
      diaSeqId,
      frameType,
      diagnosticViewId,
      anatomyId,
      isResolved,
      isSender,
      commentContent,
    });
    e.stopPropagation();
  }

  const connectSocket = async () => {
    try {
      const socketInstance = socketService.initialize();
      if (!socketInstance) {
        console.error('Failed to initialize socket');
        return;
      }
      await connectSocketInstance(socketInstance);
      socketInstance.emit('join_user_room', { conversationId: examNumber });
      setupMessageHandler(socketInstance, handleMessage);
     return () => {
        socketInstance.off("message", handleMessage);
      };
    } catch (error) {
      console.error('Error setting up socket:', error);
    }
  };

 const handleMessage = (message: ChatMessage) => {
    const data = message.content ?? message.commentContent;
    if (data) {
      fetchNotifications();
    }
  };

  const setupMessageHandler = (socketInstance: any, handleMessage: (message: ChatMessage) => void) => {
    socketInstance.off("message", handleMessage);
    socketInstance.on("message", handleMessage);
  };

  const connectSocketInstance = async (socketInstance: any) => {
    await new Promise<void>((resolve) => {
      socketInstance.on('connect', () => {
        resolve();
      });
      socketInstance.connect();
    });
  };

  return (
    <Popover open={open} onOpenChange={handlePopoverChange}>
      <PopoverTrigger asChild>
        <button
          className={`relative p-2 rounded-md transition-colors
            ${activeIcon === HeaderIcons.Notifications ? 'border border-icon-activeBorder bg-icon-activeBg' : 'border border-transparent'}`}
          onClick={(e) => 
            {
              handleIconClick(HeaderIcons.Notifications)
            }}
        >
          <Bell stroke={`${activeIcon === HeaderIcons.Notifications ? 'blue' : ''}`}/>
          {unRead && (
            <span className="absolute top-2 right-3 w-1.5 h-1.5 bg-red-500 rounded-full"></span>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className="mt-3 h-[80vh] md:h-[86vh] w-[23vw] p-2 bg-background shadow-lg rounded-lg overflow-hidden border-0">
        <div className='flex flex-row justify-between items-center gap-2 p-4'>
          <p className="text-lg text-dark-text font-semibold dark:text-radio-displayTitle">Notifications</p>
            <div className="flex items-center gap-3">
              <button 
                onClick={clearNotifications}
                className="text-notification-button text-sm font-medium italic underline"
              >
                Clear
              </button>
              <button 
                className="p-0 m-0 h-auto border-0" 
                onClick={handleClose}
              >
                <X size={16} className="text-dark-text" />
              </button>
            </div>
        </div>

        <div className="h-[90%] overflow-y-auto scrollbar-hidden">
          {Array.isArray(notifications) && notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <div 
                key={`${notification.id}_${index}`}
                className={`px-4 py-6 border-b transition-colors relative flex flex-col justify-center items-start min-h-24 md:-h-22`}
              >
                <div className="flex justify-start items-end gap-1">
                  <p className="text-sm text-notification-lightText text-left leading-2 ">{notification.content}</p>
                  {!notification.isRead && (
                    <div className="w-2 h-2 rounded-full bg-notification-button flex-shrink-0 mt-1"></div>
                  )}
                </div>
                
                {notification.label && (
                  <button
                    className="h-8 mt-1 text-notification-button text-sm border border-notification-button px-4 py-1 rounded-md"
                    onClick={(e) => {handleButtonClick(notification, e)}}
                  >
                    {notification.label}
                  </button>
                )}
                <p className="text-xs text-gray-500 mt-2">{momentFormat(notification.time, "MMM D, h:mm A")}</p>
              </div>
            ))
          ) : (
            <div className="p-4 text-center text-gray-500">
              No notifications
            </div>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default NotificationsDialog;
