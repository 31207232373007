import { io, Socket } from 'socket.io-client';

class SocketService {
  private socket: Socket | null = null;

  initialize() {
    this.socket = io(process.env.SOCKET_URL as string, {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000
    });

    this.socket.on('connect', () => {
    });

    this.socket.on('connect_error', (error) => {
      console.error('Socket connection error:', error);
    });

    this.socket.on('error', (error) => {
      console.error('Socket error:', error);
    });

    this.socket.onAny((eventName, ...args) => {
    });

    return this.socket;
  }

  getSocket() {
    if (!this.socket) {
      console.warn('Socket not initialized. Call initialize() first.');
    }
    return this.socket;
  }

  connect() {
    if (!this.socket) {
      console.warn('Cannot connect: Socket not initialized');
      return;
    }
    this.socket.connect();
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  emit(event: string, data: any) {
    if (!this.socket?.connected) {
      console.warn('Cannot emit: Socket not connected');
      return;
    }
    this.socket.emit(event, data);
  }

  on(event: string, callback: (data: any) => void) {
    if (!this.socket) {
      console.warn('Cannot listen: Socket not initialized');
      return;
    }

    this.socket.off(event);

    this.socket.on(event, (data) => {
      callback(data);
    });
  }

  off(event: string, callback: (data: any) => void) {
    if (this.socket) {
      this.socket.off(event, callback);
    }
  }

  joinRoom(roomName: string, roomData: any) {
    if (!this.socket?.connected) {
      console.warn('Cannot join room: Socket not connected');
      return;
    }
    this.socket.emit(roomName, roomData);
  }
}

export default new SocketService();