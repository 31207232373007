import { Status } from '@/types/dashboard';

export enum ReportStatusId {
  SCHEDULED = 1,
  PRELIMINARY = 2,
  IN_REVIEW = 3,
  REVISE = 4,
  FINALIZE = 6,
}

export interface StatusColors {
  bg: string;
  border: string;
}

export const getStatusColors = (status: string): StatusColors => {
  const statusColorMap = {
    [Status.Scheduled]: {
      bg: 'status-scheduled',
      border: 'status-border-scheduled'
    },
    [Status.InReview]: {
      bg: 'status-inReview',
      border: 'status-border-inReview',
    },
    [Status.Preliminary]: {
      bg: 'status-preliminary',
      border: 'status-border-preliminary'
    },
    [Status.Revise]: {
      bg: 'status-revised',
      border: 'status-border-revised'
    },
    [Status.Finalized]: {
      bg: 'status-completed',
      border: 'status-border-completed'
    }
  };
  return statusColorMap[status as Status] || { stroke: '#000000', bg: 'red', text: '#00000' };
};

export const getStatusClasses = (status: string): string => {
  const statusClassMap = {
    [Status.Scheduled]: "border-status-border-scheduled bg-status-scheduled text-status-text-scheduled",
    [Status.InReview]: "border-status-border-inReview bg-status-inReview text-status-text-inReview",
    [Status.Preliminary]: "border-status-border-preliminary bg-status-preliminary text-status-text-preliminary",
    [Status.Revise]: "border-status-border-revised bg-status-revised text-status-text-revised",
    [Status.Finalized]: "border-status-border-completed bg-status-completed text-status-text-completed"
  };
  return statusClassMap[status as Status] || "";
};
