export interface HeaderData {
    patientName: string;
    patientId: string;
    accessionNumber: string;
    lmp: string;
    ga: string;
    edd: string;
  }
    export const headerData: HeaderData = {
    patientName: 'Jessica Robinson',
    patientId: 'PID-067886545',
    accessionNumber: 'ACC-7890-EFGH',
    lmp: '04/23/2023',
    ga: '12w2d',
    edd: '01/28/2024',
  }; 