// ... existing code ...
import ReportBuilder from '../../public/images/report-builder.svg'
import PatientProfile from '../../public/images/patient-profile.svg'
import Gallery from '../../public/images/gallery.svg'
import DashBoard from '../../public/images/dashboard.svg'

export const menu = {
  dashboard: {
    path: "/dashboard",
    label: "DashBoard",
    iconName: 'Dashboard',
    icon: DashBoard,
  },
} as const

export const routes = {
  reportBuilder: {
    path: "/report-builder",
    label: "Report Builder",
    iconName: 'ReportBuilder',
    icon: ReportBuilder,
  },
  gallery: {
    path: "/gallery",
    label: "Gallery",
    iconName: 'Gallery',
    icon: Gallery,
  },
  patientProfile: {
    path: "/patient-profile",
    label: "Patients",
    iconName: 'PatientProfile',
    icon: PatientProfile,
  }
} as const