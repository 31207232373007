export enum Status {
  Scheduled = "Scheduled",
  InReview = "In Review",
  Preliminary = "Preliminary",
  Revise = "Revise",
  Finalized = "Finalized"
}

export const statusColors = {
  [Status.Scheduled]: "bg-status-scheduled",
  [Status.InReview]: "bg-status-inReview", 
  [Status.Preliminary]: "bg-status-preliminary",
  [Status.Revise]: "bg-status-revised",
  [Status.Finalized]: "bg-status-completed",
} as const;

export const statusToId = {
  'All Studies': '',
  [Status.Scheduled]: '1',
  [Status.Preliminary]: '2',
  [Status.InReview]: '3',
  [Status.Revise]: '4',
  [Status.Finalized]: '6'
} as const;

export const statusColorMap = {
  [Status.Scheduled]: 'border-status-border-scheduled',
  [Status.InReview]: 'border-status-border-inReview',
  [Status.Preliminary]: 'border-status-border-preliminary',
  [Status.Revise]: 'border-status-border-revised',
  [Status.Finalized]: 'border-status-border-completed',
  'All Studies': 'border-status-border-allStudies',
} as const;
