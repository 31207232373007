'use client';

import { FC, useEffect, useRef, useState } from 'react';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { X } from 'lucide-react';
import Pencil from '../svg/pencil';
import Vector from '../svg/vector';
import socketService from '../../utlity/socket-service';
import { apiService } from '../../utlity/api-service';
import { API_LIST } from '../../utlity/api-list';
import { ChatDialogProps, HeaderIcons, ChatMessage, commentStatus, PatientNotesResponse } from '../../types/chat';
import { momentFormat } from '../../utlity/format';
import { MessageInput } from './mention-input';
import Tag from '../svg/tag';
import { useInView } from "react-intersection-observer";
import { useExamStore } from '@/store/use-exam-store';
import { useParams, usePathname } from 'next/navigation';
import { useScroll } from '@/context/scroll-context';
import { useReportStore } from '@/store/use-report-store';
import { EActiveReportTab } from '@/enums/base-enum';
import { useCurrentImage } from '@/hooks/use-current-image';

const ChatDialog: FC<ChatDialogProps> = ({ open, onOpenChange, activeIcon, setActiveIcon }) => {
  const [unRead, setUnRead] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [offset, setOffset] = useState(0);
  const limit = 13;
  const [hasMore, setHasMore] = useState(true);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const { scrollToContent } = useScroll();
  const { setActiveSectionId, selectedHeaderDetails, reportTemplateHeaders, report, setActiveReportSection } = useReportStore();
  const path = usePathname()
  const { currentImageDetails } = useCurrentImage();

  const { ref: loadMoreRef } = useInView({
    threshold: 0,
    rootMargin: '100px',
    triggerOnce: false,
  });

  const [notes, setNotes] = useState<string>("")
  const [isEditing, setIsEditing] = useState(false);
  const [editableNotes, setEditableNotes] = useState(notes || '');  
  const { setIsChatOpen, activeAnotomyReferences, setActiveComment } = useExamStore();
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);

  const { examId } = useParams<{ examId: string }>() ?? {};
  const examNumber = examId ? Number(examId) : null;

  useEffect(() => {
    if (open) {
      fetchMessages();
    }
    setIsEditing(false);
    setActiveComment(null)
    setSelectedMessageId(null);
  }, [open]);

  useEffect(() => {
    setIsChatOpen(false);
    handleClose()
  }, [path]);

  const fetchMessages = async () => {
    try {
      if (hasMore) {
        const response = await apiService.get<ChatMessage[]>(`${API_LIST.GET_MESSAGES}/${examId}?limit=${limit}&offset=${offset}`);
          if (response?.data?.length > 0) {
            setMessages((prevMessages) => {
              const combinedMessages = [...prevMessages, ...response.data];
              const sortedMessages = combinedMessages.toSorted((a: ChatMessage, b: ChatMessage) => b.id - a.id);
              return sortedMessages;
            });
            setOffset((prev) => prev + limit);
          }
          if (response?.data?.length < limit) {
            setHasMore(false);
          }
      }
    } catch {
    } 
  };

  const fetchNotes = async () => {
    try {
      const response = await apiService.get<PatientNotesResponse>(`${API_LIST.GET_PATIENT_NOTES}/${examId}`);
      setNotes(response?.data?.value ?? "");
    } catch {
      setNotes('');
    }
  }

  const handleClose = () => {
    setUnRead(false);
    onOpenChange(false);
    setIsChatOpen(false);
    setActiveComment(null);
  };

  useEffect(() => {
    connectSocket();
    fetchNotes();
    setActiveComment(null);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages, onOpenChange]);

  const connectSocket = async () => {
    try {
      const socketInstance = socketService.initialize();
      if (!socketInstance) {
        console.error('Failed to initialize socket');
        return;
      }
      await new Promise<void>((resolve) => {
        socketInstance.on('connect', () => {
          resolve();
        });
        socketInstance.connect();
      });
      socketService.joinRoom('join_chat_room', { conversationId: examNumber });
      socketInstance.off("message", handleMessage);
      socketInstance.on('message', handleMessage);
      return () => {
        socketInstance.off("message", handleMessage);
      };
    } catch {
    }
  };

  const handleMessage = (message: ChatMessage) => {
    setMessages((prevMessages) => {
      const combinedMessages = [...prevMessages, message];
      const sortedMessages = combinedMessages.toSorted((a: ChatMessage, b: ChatMessage) => b.id - a.id);
      setOffset(sortedMessages.length + 1);
      return sortedMessages;
    });
    if (!message.isSender) {
      setUnRead(true);
    }
  };

  const handleIconClick = (iconName: string) => {

    if (iconName === 'message' && !open) {
      setActiveIcon(null)
      setUnRead(false);
      socketService.emit('message', { convId: examNumber });
      setIsChatOpen(true)
    } else {
      setIsChatOpen(false)
      setActiveComment(null)
      setActiveIcon(iconName)
    }
    setActiveComment(null)
    onOpenChange(!open);
  }

  const handleSendMessage = async (message: string, coordinates?: string) => {
    try {
      const payload = {
        content: message,
        frameId: activeAnotomyReferences.frameId,
        coordinates: coordinates,
        examId: examId,
        diaSeqId: activeAnotomyReferences.diagnosticViewId,
        conversationId: examId ? Number(examId) : null,
        frameType: activeAnotomyReferences.frameType,
        label: currentImageDetails.label,
        thumbnailId: activeAnotomyReferences.frameReference.thumbnailId,
      }
      await apiService.post(`${API_LIST.SEND_MESSAGE}`, payload);
    } catch {
      setMessages([]);
    }
  };

  const handleEditClick = () => {
    setEditableNotes(notes);
    setIsEditing(true);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditableNotes(e.target.value);
  };

  const handleSaveNotes = async () => {
    try {
      setNotes(editableNotes);
      const payload = {
        examId: examId,
        value: editableNotes,
      }
      await apiService.post(`${API_LIST.SAVE_PATIENT_NOTES}`, payload);
      setIsEditing(false);
    } catch {
      setIsEditing(false);
    }
  }

  const handleCancelNotes = () => {
    setEditableNotes(notes);
    setIsEditing(false);
  };

  const getTagLabel = (tagId: number | null): string => {
    if (!tagId) return '';
    const tag = reportTemplateHeaders.find((item) => item.headerId === tagId);
    return tag?.header ?? '';
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current && open) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (chatContainerRef.current) {
      const { scrollTop } = chatContainerRef.current;
      if (scrollTop === 0 && hasMore) {
        setOffset((prevOffset) => prevOffset + limit);
        fetchMessages();
      }
    }
  };

  const handleToggleCommentStatus = async (commentId: number) => {
    try {
      const response = await apiService.post(`${API_LIST.TOGGLE_COMMENT_STATUS}/${commentId}`);
      if (response.success) {
        const updatedMessages = messages.map((message) => {
          if (message.id === commentId) {
            return { ...message, isResolved: !message.isResolved };
          }
          return message;
        });
        setMessages(updatedMessages);
      }
    } catch {
    }
  }

  const onOpenPopover = () => {
    onOpenChange(!open)
    handleIconClick('message')
  }

  const handleContentClick = (contentId: number) => {
    setActiveSectionId(contentId);
    const header = report.find(section => section.tabname === EActiveReportTab.FETAL_EVALUATION )?.fields || [];
    const tab = header.some(item => item.headerId === contentId);
    if( tab ){
      setActiveReportSection(EActiveReportTab.FETAL_EVALUATION)
      scrollToContent(contentId);
    }
    else {
      setActiveReportSection(EActiveReportTab.GENERAL)
      scrollToContent(contentId);
    }
  };

  const handleCommentClick = (message: ChatMessage) => {
    if (message) {
      setSelectedMessageId(message.id);
      setActiveComment(message);
      handleToggleCommentStatus(message?.commentId ?? 0);
    }
  }

  const getCommentStatus = (message: ChatMessage) => {
    return message.isResolved ? commentStatus.RESOLVED : commentStatus.UNRESOLVED;
  };

  const getButtonClasses = (message: ChatMessage, isSelected: boolean) => {
    const baseClasses = 'border rounded-lg px-2 pt-4 pb-2 mr-auto text-left';

    if (!message.isResolved) {
      return `${baseClasses} bg-chat-resolvedBackground border-chat-resolvedBorder
        ${isSelected 
          ? 'bg-chat-resolvedHoverBackground border-chat-resolvedHoverBorder'
          : 'hover:bg-chat-resolvedHoverBackground hover:border-chat-resolvedHoverBorder'
        }
        `;
    }
    
    return `${baseClasses} bg-chat-unresolvedBackground border-chat-unresolvedBorder
      ${isSelected 
        ? 'bg-chat-unresolvedHoverBackground border-chat-unresolvedHoverBorder'
        : 'hover:bg-chat-unresolvedHoverBackground hover:border-chat-unresolvedHoverBorder'
      }
      `;
  };

  const renderMessage = (message: ChatMessage, index: number) => {
    if (message.commentContent) {
      return (
        <div className={`${message.isSender ? 'justify-items-end' : 'justify-items-start'} max-w-[180px] overflow-hidden`}>
        <button
          key={message.id +'-'+index}
          className={getButtonClasses(message, selectedMessageId === message.id)}
          onClick={() => handleCommentClick(message)}
        >
          <div className="flex items-center gap-2 mb-2">
            <div className="flex items-center text-chat-commentTagText bg-chat-commentTagBackground border border-chat-commentTagBorder px-2 py-1 gap-2 rounded-full">
              <Vector className='w-3 h-3 stroke-chat-commentTagText' />
              <span className="text-sm md:text-xs">{message.label}</span>
            </div>
          </div>
          <p className="text-sm text-chat-commentTagText line-clamp-3 max-w-[96%]">
             {message.commentContent}
          </p>
          <div className="flex justify-between items-center mt-2">
            <span className="text-xs text-gray-500 w-24">{momentFormat(message.createdAt, "MMM D, h:mm A")}</span>
            <span className="text-chat-resolveButton text-xs w-16 pl-4">
              {getCommentStatus(message)}
            </span>
          </div>
        </button>
        </div>
      );
    }

    if (message.content) {
      const mentionIdMatch = /{{(\d+)}}/.exec(message.content);
      const mentionLabel = mentionIdMatch ? getTagLabel(parseInt(mentionIdMatch[1])) : '';
      const parts = message.content.split(/{{\d+}}/g);

      return (
        <>
          <button 
            key={message.id +'-'+index}
            onClick={() => {
              if (mentionIdMatch) {
                const contentId = parseInt(mentionIdMatch[1]);
                handleContentClick(contentId);
              }
            }}
            className={`border ${mentionLabel
              ? 'bg-chat-chatWithTagBackground border-chat-chatBorder p-0'
              : 'bg-chat-chatBackground border-chat-chatBorder'}
              rounded-lg p-3 min-w-[32%] max-w-[62%] flex flex-wrap items-center gap-2 cursor-auto`}
          >
            <p className="text-sm text-left">
              {parts[0]}
            </p>
            {mentionLabel && (
              <div className="bg-chat-commentTagBackground border border-chat-commentTagBorder text-chat-commentTagText rounded-full px-2 py-1 text-sm flex flex-row items-center gap-1 cursor-pointer">
                <Tag className='w-4 h-4 stroke-chat-commentTagText text-chat-commentTagText' />
                <p className='text-chat-commentTagText'>{mentionLabel}</p>
              </div>
            )}
          </button>
          <span className="text-[10px] text-gray-500 mt-1">
            {momentFormat(message.createdAt)}
          </span>
        </>
      );
    }
  };

  return (
    <Popover open={open} onOpenChange={() => { }}>
      <PopoverTrigger asChild>
        <button
          className={`relative p-2 rounded-md transition-colors
            ${activeIcon === HeaderIcons.Chat ? 'border border-icon-activeBorder bg-icon-activeBg' : 'border border-transparent'}`}
          onClick={onOpenPopover}
        >
          <Vector stroke={`${activeIcon === HeaderIcons.Chat ? 'blue' : ''}`} />
          {unRead && (
            <span className="absolute top-2 right-3 w-1.5 h-1.5 bg-red-500 rounded-full"></span>
          )}
        </button>
      </PopoverTrigger>
      <PopoverContent className="mt-3 h-[86vh] w-[23vw] p-0 ml-0 bg-background shadow-lg rounded-lg overflow-hidden border-0">
        <div className='flex flex-row justify-between items-center gap-2 px-4 py-1'>
          <p className="text-lg text-dark-text font-medium">Chats</p>
          <button
            className="p-0 m-0 h-auto border-0"
            onClick={handleClose}
          >
            <X size={16} className="text-dark-text" />
          </button>
        </div>
        <div className="sticky top-0 z-10 border-y-[1px] border-header-borderLine bg-background p-2">
          <div className="bg-chat-chatBackground border-[1px] border-header-borderLine rounded-md p-2 ">
            <div className="bg-chat-chatBackground rounded-sm p-0">
              <p className="text-md text-dark-text font-medium mb-2">Pregnancy notes</p>
              <div className="bg-background border-[1px] border-header-borderLine p-3 rounded-sm relative h-32">
                {isEditing ? (
                  <>
                    <textarea
                      value={editableNotes}
                      onChange={handleNotesChange}
                      maxLength={250}
                      className="w-full mb-1 h-[70px] max-h-[100px] text-sm resize-none outline-none bg-transparent overflow-y-auto scrollbar-thin scrollbar-hidden"
                      autoFocus
                      onFocus={(e) => e.target.setSelectionRange(e.target.value.length, e.target.value.length)}
                    />
                    <div className="flex justify-end gap-2 absolute bottom-2 right-2">
                      <button
                        onClick={handleCancelNotes}
                        className="rounded-sm text-chat-resolveButton text-sm transition-all duration-200 px-1"
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleSaveNotes}
                        className="rounded-sm bg-user-editButton hover:bg-chat-resolveButton text-sm text-white transition-all duration-200 px-1"
                      >
                        Save
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className="bg-background text-sm h-[80px] max-h-[100px] mb-[18px] overflow-y-auto scrollbar">{notes}</p>
                    <button
                      onClick={handleEditClick}
                      className="absolute bottom-2 right-2 mt-2 text-gray-400"
                    >
                      <Pencil className="h-6 w-6" />
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[55vh] overflow-y-auto scrollbar scrollbar-hidden" ref={chatContainerRef} onScroll={handleScroll}>
          <div className="flex flex-col-reverse gap-4 p-2">
            {messages.map((message, index) => (
              <div
                key={message.id +'-'+index}
                className={`${message.isSender ? 'justify-items-end' : 'justify-items-start'}`}
              >
                {renderMessage(message, index)}
              </div>
            ))}
            <div ref={loadMoreRef} />
          </div>
        </div>
        <div className="absolute bottom-0 w-full py-2 px-4 static">
          <MessageInput
            mentionItems={selectedHeaderDetails}
            onSend={handleSendMessage}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default ChatDialog;