import { useState, useRef, useEffect } from 'react';
import SendMessage from '../svg/send-message';

interface MentionItem {
  readonly id: number;
  readonly label: string;
}

interface MessageInputProps {
  readonly mentionItems: readonly MentionItem[];
  readonly onSend: (message: string) => void;
}

export function MessageInput({
  mentionItems,
  onSend,
}: MessageInputProps) {
  const [inputValue, setInputValue] = useState('');
  const [showMentions, setShowMentions] = useState(false);
  const [filteredItems, setFilteredItems] = useState<MentionItem[]>([]);
  const [triggerPosition, setTriggerPosition] = useState<number | null>(null);
  const [cursorPosition, setCursorPosition] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const mentionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
    }
  }, [cursorPosition]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (mentionRef.current && !mentionRef.current.contains(e.target as Node) && 
          inputRef.current && !inputRef.current.contains(e.target as Node)) {
        setShowMentions(false);
      }
    };

    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const position = e.target.selectionStart ?? 0;
    
    setInputValue(value);
    setCursorPosition(position);
    
    const lastAtIndex = value.lastIndexOf('@', position - 1);
    
    if (lastAtIndex !== -1 && (lastAtIndex === 0 || value[lastAtIndex - 1] === ' ')) {
      const query = value.substring(lastAtIndex + 1, position).toLowerCase();
      setTriggerPosition(lastAtIndex);
      
      const filtered = mentionItems.filter(item => 
        item.label.toLowerCase().startsWith(query)
      );
      
      setFilteredItems(filtered);
      setShowMentions(filtered.length > 0);
    } else {
      setShowMentions(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !showMentions) {
      e.preventDefault();
      handleSend();
    } else if (e.key === 'Escape' && showMentions) {
      setShowMentions(false);
    }
  };

  const handleMentionSelect = (item: MentionItem) => {
    if (triggerPosition !== null) {
      const beforeTrigger = inputValue.substring(0, triggerPosition);
      const afterTrigger = inputValue.substring(cursorPosition);
      const newValue = `${beforeTrigger}@${item.label} ${afterTrigger}`;
      
      setInputValue(newValue);
      const newCursorPosition = triggerPosition + item.label.length + 2;
      setCursorPosition(newCursorPosition);
      setShowMentions(false);
      
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleSend = () => {
    if (inputValue.trim()) {
      const messageWithIds = replaceMentionsWithIds(inputValue);
      onSend(messageWithIds);
      setInputValue('');
      setShowMentions(false);
    }
  };

  const replaceMentionsWithIds = (message: string): string => {
    return message.replace(/@([\w\s&]+)/g, (match, mentionLabel) => {
      const mentionItem = mentionItems.find(item => item.label.toLowerCase() === mentionLabel.toLowerCase().trim());
      return mentionItem ? `{{${mentionItem.id}}}` : match;
    });
  };

  const renderFormattedInput = () => {
    return (
      <div className="flex-1 relative">
        <input
          ref={inputRef}
          type="text"
          value={inputValue}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          placeholder="Click here to start typing.."
          className={`w-full outline-none text-sm italic bg-transparent text-transparent caret-gray-400`}
          style={{ caretColor: 'gray' }}
        />
        <div 
          className="absolute inset-0 pointer-events-none flex items-center text-sm italic"
          style={{
            color: 'inherit'
          }}
        >
        {inputValue?.split(/(@[\w\s&]+)/).map((part, index) => (
          <span
            key={`${part}-${index}`}
            style={{
              color: mentionItems.some(item => 
                part.trim() === item.label || part.trim() === '@' + item.label
              ) ? '#2563eb' : 'inherit'
            }}
          >
            {part}
          </span>
        ))}
        </div>
      </div>
    );
  };

  return (
    <div className={`relative items-center`}>
      <div className="flex items-center h-11 gap-2 bg-chat-sendBackground border border-chat-sendBorder rounded-full pl-4 pr-2">
        {renderFormattedInput()}
        <button
          onClick={handleSend}
          className="p-1.5 rounded-lg hover:bg-gray-100 transition-colors"
          disabled={!inputValue.trim()}
        >
          <SendMessage />
        </button>
      </div>
      
      {showMentions && (
        <div 
          ref={mentionRef}
          className="absolute left-[20%] bottom-8 mb-2 w-40 bg-background border rounded-lg shadow-lg z-100 max-h-40 overflow-y-auto scrollbar scrollbar-hidden"
        >
          <p className="sticky top-0 bg-background text-xs font-medium text-radio-displaySecondaryText text-gray-400 px-3 py-2">
            SECTIONS
          </p>
          {filteredItems.map((item: MentionItem) => (
            <button
            key={item.id}
            tabIndex={0}
            className={`w-full text-left px-3 py-[2px] hover:bg-chat-chatBackground cursor-pointer text-sm truncate`}
            onClick={() => handleMentionSelect(item)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleMentionSelect(item);
              }
            }}
          >
            {item.label}
          </button>
          ))}
        </div>
      )}
    </div>
  );
}