import React, { useEffect, useRef } from 'react';
import Close from '@/components/svg/close'; 
import { SelectDropdown } from '@/components/ui/select-dropdown';
import { cn } from '@/lib/utils';
import { getStatusClasses } from '@/types/report-status';
import { ICollaboratorsDetails } from '@/data/collaborators-data';

interface ExamCollaboratorsDropdownProps {
  onClose: () => void;
  options: ICollaboratorsDetails;
}

const handleAuthorChange = (value: number) => {};
const handleReadingPhysicianChange = (value: number) => {};
const handleReferringPhysicianChange = (value: number) => {};

const ExamCollaboratorsDropdown: React.FC<ExamCollaboratorsDropdownProps> = ({ onClose, options }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  return (
    <div ref={dropdownRef} className="absolute mt-1 left-0 w-[400px] bg-builder-background border border-header-borderLine rounded-[15px] shadow-[0_3px_8px_0_hsla(0,0%,0%,0.4)] p-3 z-50 font-roboto">
      <div className="flex justify-between items-center border-b border-header-borderLine pb-1 -mx-3 px-3 mb-3">
        <h2 className="text-builder-darkText font-[600] text-md font-family-roboto">Exam Collaborators</h2>
        <button onClick={onClose} className="text-dropdown-closeIcon p-1 hover:bg-gray-200 rounded-full transition-colors">
          <Close stroke="hsl(var(--dropdown-close-icon))" className="w-4 h-4" />
        </button>
      </div>
      <span 
        className={cn(
          "mb-2 w-[120px] h-[32px] rounded border text-center font-medium uppercase",
          "text-[11px] sm:text-xs md:text-sm",
          "font-family-roboto font-semi-bold flex items-center justify-center",
          getStatusClasses(options.statusName)
        )}
      >
        {options.statusName}
      </span>
      {/* Author Select */}
      <div className="mb-1 flex items-center gap-2">
        <span className="w-2/5 text-xs text-dropdown-primaryText whitespace-nowrap font-bold font-family-roboto">
          Author:
        </span>
        <div className="w-3/5 pb-1">
          <SelectDropdown
            options={options?.authors || []}
            onChange={handleAuthorChange}
            disabled={true}
          />
        </div>
      </div>
      {/* Reading Physician Select */}
      <div className="mb-1 flex items-center gap-2">
        <span className="w-2/5 text-xs text-dropdown-primaryText whitespace-nowrap font-bold text-[13px]">
          Reading Physician:
        </span>
        <div className="w-3/5 pb-1">
          <SelectDropdown
            options={options?.readingPhysicians || []}
            onChange={handleReadingPhysicianChange}
            disabled={true}
          />
        </div>
      </div>
      {/* Referring Physician Select */}
      <div className="mb-0 flex items-center gap-2">
        <span className="w-2/5 text-xs text-dropdown-primaryText whitespace-nowrap font-bold">
          Referring Physician:
        </span>
        <div className="w-3/5">
          <SelectDropdown
            options={options?.referringPhysicians || []}
            onChange={handleReferringPhysicianChange}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ExamCollaboratorsDropdown;