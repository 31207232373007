import { cn } from "@/lib/utils"
import IndicatorChecked from '@/components/svg/indicator-checked';
import Warn from '@/components/svg/warn';
import DarkWarn from '../../../public/images/darkwarn.svg';
import DarkChecked from '../../../public/images/darkcheck.svg';
import Image from 'next/image';
interface AnatomyButtonProps {
  name: string
  isSelected?: boolean
  onClick?: () => void
  isVerified: boolean
  [key: string]: any 
}
export const AnatomyButton: React.FC<AnatomyButtonProps> = ({ 
  name, 
  isVerified, 
  isSelected,
  isAutoCorelation,
  isScrollSyncEnabled, 
  onClick,
  ...dataProps
}) => {
  const buttonStyles: string = cn(
    "flex-none inline-flex items-center justify-center gap-2 px-4 py-1.5 rounded-full text-sm transition-all shadow-md my-2",
    "bg-builder-innerBackground",
    "shadow-anatomy",
    "hover:bg-active-iconBg",
    "text-anatomy-button-text",
    ((isSelected && !isScrollSyncEnabled) || isAutoCorelation) && "bg-card-active"
  )

  const selectedStyles: string = ((isSelected && !isScrollSyncEnabled) || isAutoCorelation)  ? "bg-anotomy-typeHover dark:bg-anotomy-typeHover dark:shadow-[4px_4px_8px_0px_#1143a642_inset] dark:border-0" : ""
  return (
    <button
      className={`${buttonStyles} ${selectedStyles}`}
      onClick={onClick}
      {...dataProps}
    >
      {isVerified ? (
        <>
          <IndicatorChecked className="text-status-success dark:hidden w-4 h-4" />
          <Image src={DarkChecked} alt="Verified" className="hidden dark:block w-4 h-4" />
        </>
      ) : (
        <>
          <Warn className="text-status-warning dark:hidden w-4 h-4" />
          <Image src={DarkWarn} alt="Warning" className="hidden dark:block w-4 h-4" />
        </>
      )}
      <span className={`truncate ${((isSelected && !isScrollSyncEnabled) || isAutoCorelation)  ? "text-anotomy-typeHoverText dark:text-switchTab-text" : "text-dropdown-secondaryText"}`}>{name}</span>
    </button>
  )
}