"use client"

import { usePathname } from "next/navigation"
import { routes, menu } from "@/constants/routes"
import Image from "next/image"
import OmeaLogo from '../../../public/images/omea-logo.svg'
import OmeaLogoDark from '../../../public/images/omea-logo-dark.svg'
import { useTheme } from 'next-themes'
import SidebarItem from './sidebar-item'
import { useExamStore } from "@/store/use-exam-store"
import { Status } from "@/enums/dashboard-enum"
import { useSplitScreen } from "@/context/split-screen-context"

export default function Sidebar() {
  const pathname: string | null = usePathname() ?? '';
  const { theme } = useTheme()
  const { examDetails } = useExamStore();
  const { isSplitScreen } = useSplitScreen();
  const examIdMatch: RegExpMatchArray | null = RegExp(/\/(?:dashboard|report-builder|gallery|patient-profile|report-section)\/(\d+)/).exec(pathname)
  const examId: string | null = examIdMatch ? examIdMatch[1] : null
  const { isSecondaryWindow } = useSplitScreen();


  return (
    <div className="w-[70px] h-[calc(100vh-4px)] mt-1 rounded-lg flex flex-col items-center bg-surface relative">
      <div className={`w-[56px] h-[58px] flex flex-col items-center justify-center m-2 rounded-lg bg-chat-tagBackground dark:bg-card-static border border-header-borderLine dark:border-icon-activeBorder`}>
        <Image src={theme === 'dark' ? OmeaLogoDark : OmeaLogo} alt="OMEA Logo" width={24} height={30} />
      </div>

      <nav className="w-16 rounded-lg h-2/5 flex flex-col items-center justify-between gap-3">
        {!isSecondaryWindow && Object.values(menu).map((item) => {
          const isActive = pathname === item.path
          return (
            <SidebarItem 
              key={item.path} 
              route={item} 
              isActive={isActive} 
              href={item.path} 
            />
          )
        })}
        
        {!pathname?.includes('/dashboard') && !pathname?.includes('/settings') && !pathname?.includes('/report-section') && !isSecondaryWindow && (
          <div className="w-16 flex flex-col items-center gap-4">
            {Object.values(routes).map((route) => {
              const href: string = examId ? `${route.path}/${examId}` : route.path;

              let isActive: boolean;
              if (!isSplitScreen) {
                isActive = href === pathname;
              } else if (route.path === '/report-builder') {
                isActive = ['/report-builder', '/report-section'].some(path => pathname.includes(path));
              } else {
                isActive = pathname === href;
              }

              if ((examDetails?.status === Status.Scheduled) && (route.path.includes('/gallery') || route.path.includes('/report-builder')))  return null
              return (
                <SidebarItem 
                  key={route.path} 
                  route={route} 
                  isActive={isActive} 
                  href={href}
                />
              )
            })}
          </div>
        )}
      </nav>
    </div>
  )
}