import { cn } from "@/lib/utils";

interface CollaboratorsProps {
  readonly className?: string;
  readonly stroke?: string;
  readonly fill?: string;
}

export default function Collaborators({ className = '', stroke = '', fill = 'none' }: CollaboratorsProps) {
  return (
<svg className={cn(className)} width="39" height="39" viewBox="0 0 27 26" fill={fill} xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 18.2812C15.7437 18.2812 17.5625 16.4624 17.5625 14.2188C17.5625 11.9751 15.7437 10.1562 13.5 10.1562C11.2563 10.1562 9.4375 11.9751 9.4375 14.2188C9.4375 16.4624 11.2563 18.2812 13.5 18.2812Z" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M20.4062 11.7813C21.3524 11.7805 22.2857 12.0003 23.1319 12.4234C23.9782 12.8464 24.7142 13.461 25.2814 14.2183" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M1.71826 14.2185C2.28543 13.4612 3.02141 12.8465 3.86772 12.4235C4.71404 12.0004 5.64737 11.7805 6.59355 11.7813" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M7.65332 21.9374C8.18705 20.8411 9.01825 19.917 10.052 19.2705C11.0858 18.6241 12.2805 18.2813 13.4998 18.2813C14.7191 18.2812 15.9138 18.624 16.9476 19.2705C17.9814 19.917 18.8126 20.8411 19.3463 21.9374" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.59363 11.7813C5.97698 11.7811 5.37309 11.6056 4.85248 11.2751C4.33186 10.9446 3.91601 10.4729 3.65348 9.91489C3.39096 9.35691 3.29258 8.73577 3.36986 8.12398C3.44713 7.51219 3.69687 6.93501 4.08989 6.45984C4.48292 5.98467 5.00301 5.63112 5.58945 5.44047C6.17589 5.24983 6.80447 5.22995 7.40178 5.38317C7.99909 5.53639 8.54048 5.85638 8.96274 6.30578C9.38499 6.75517 9.67069 7.31542 9.78645 7.92111" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M17.2134 7.92074C17.3291 7.31496 17.6148 6.7546 18.037 6.30511C18.4593 5.85561 19.0007 5.53554 19.5981 5.38226C20.1955 5.22898 20.8242 5.24882 21.4107 5.43947C21.9972 5.63012 22.5174 5.98371 22.9105 6.45894C23.3036 6.93417 23.5533 7.51143 23.6306 8.1233C23.7079 8.73517 23.6095 9.3564 23.3469 9.91444C23.0843 10.4725 22.6683 10.9443 22.1476 11.2748C21.6269 11.6053 21.0229 11.7808 20.4062 11.7808" stroke={stroke} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  );
}