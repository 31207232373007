import React, { useState, useRef, useEffect } from 'react';
import { ArrowDown } from '@/components/svg/arrow-down';
import { ICollaboratorOption } from '@/data/collaborators-data';
interface SelectDropdownProps {
  options: Array<ICollaboratorOption>;
  disabled?: boolean;
  onChange: (value: number) => void;
}

export const SelectDropdown: React.FC<SelectDropdownProps> = ({ 
  options, 
  onChange, 
  disabled = false 
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>(options[0]?.name || '');
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionSelect = (value: number, label: string) => {
    onChange(value);
    setSelected(label);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, []);

  return (
    <div className="flex-1 relative" ref={dropdownRef}>
      <button 
        type="button"
        onClick={toggleDropdown}
        disabled={disabled}
        className={`flex items-center justify-between w-full h-8 border border-dropdown-border rounded px-2 py-1.5 bg-dropdown-selectionBg text-dropdown-secondaryText text-[12px] ${disabled ? 'opacity-70 cursor-not-allowed' : ''}`}
      >
        <span className="pl-1 capitalize">{selected}</span>
        <div className="absolute right-0 top-1/2 transform -translate-y-1/2 w-9 h-8 flex items-center justify-center bg-dropdown-selectionArrow rounded-r">
          <ArrowDown className={`${isOpen ? "rotate-180" : ""} transition-transform`} width="16" height="16" stroke="#5C5F66" />
        </div>
      </button>
      {isOpen && (
        <div className="absolute w-full mt-1 border border-dropdown-border rounded bg-dropdown-selectionBg text-dropdown-secondaryText shadow-lg z-10 text-[12px] py-1">  
          {options.map((option: ICollaboratorOption, index) => (
            <button
              key={option?.id}
              type="button"
              onClick={() => handleOptionSelect(option?.id, option?.name)}
              className={`w-full text-left px-3 py-1.5 hover:bg-dropdown-selection capitalize ${index !== options.length - 1 ? 'border-b border-dropdown-border' : ''}`}
            >
              {option?.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;      