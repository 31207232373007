"use client"
import React, { useEffect, useState } from 'react';
import CaretRight from '@/components/svg/caret-right';
import { Button } from "@/components/ui/button";

import { AnatomyButton } from './anotomy-sections';
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import ExamCollaboratorsDropdown from './exam-collaborators-dropdown';
import { apiService } from '@/utlity/api-service';
import { API_LIST } from '@/utlity/api-list';
import { ICollaboratorsDetails } from '@/data/collaborators-data';
import useModal from '@/hooks/use-modal';
import { useExamStore } from '@/store/use-exam-store';
import { EAnatomyStatus } from '@/enums/base-enum';
import { useScroll } from '@/context/scroll-context';
import useSetAnotomyReference from '@/hooks/use-set-anotomy-reference';
import Modal from '../ui/modal';
import Close from '../svg/close';
import { Input } from '../ui/input';
import { useReportStore } from '@/store/use-report-store';
import { useParams, usePathname } from 'next/navigation';
import { HEADER_OFFSETS } from '@/constants/layout';
import { useScrollSync } from '@/store/use-scroll-sync-store';
import { useClickOutside } from '@/hooks/gallery.ts/use-click-outside';
import Collaborators from '../svg/collaborators';
import { getStatusColors } from '@/types/report-status';

const AnotomyPanel: React.FC = () => {

  const reportTemplateChannel = typeof window !== "undefined" ? new BroadcastChannel("report_template_sync") : null;
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  const tabId = array[0].toString(36);

  const { anatomyScrollRef, scrollToSection } = useScroll();
  const { examData, activeAnotomyReferences } = useExamStore();
  const setAnotomyRef = useSetAnotomyReference();
  const [showDropdown, setShowDropdown] = useState(false);
  const [collaboratorsDetails, setCollaboratorsDetails] = useState<ICollaboratorsDetails | null>(null);
  const { isOpen, openModal, closeModal } = useModal();
  const { setSelectedSections, updateFilledFields, selectedSections, setReportTemplateHeadersInStore } = useReportStore();
  const { examId } = useParams<{ examId: string }>() ?? {};
  const pathname: string = usePathname() ?? "";
  const headerOffset: number = pathname.includes('report-builder') ? HEADER_OFFSETS.REPORT_BUILDER : HEADER_OFFSETS.GALLERY;
  const [tempCheckedSections, setTempCheckedSections] = useState<number[]>([]);
  const [templateSectionSelection, setTemplateSectionSelection] = useState<boolean>(false);
  const [reportTemplateHeaders, setReportTemplateHeaders] = useState<{ "headerId": number, "header": string }[]>([]);
  const canPrevious: boolean = examData?.findIndex(item => item.anatomyId === activeAnotomyReferences?.anatomyId) <= 0;
  const canNext: boolean = examData?.findIndex(item => item.anatomyId === activeAnotomyReferences?.anatomyId) >= examData.length - 1;

  const { activeScrolledSectionId, isScrollSyncEnabled } = useScrollSync();

  const templateHeaderDropdown = useClickOutside<HTMLDivElement>(() => setTemplateSectionSelection(false));

  useEffect(() => {
    fetchReportTemplateHeaders()
    updateFilledFields();

    if (activeAnotomyReferences?.anatomyId === 0 && examData?.[0]?.anatomyId) {
      const firstAnatomyId: number = examData?.[0]?.anatomyId;
      setAnotomyRef(firstAnatomyId);
    }
  }, [activeAnotomyReferences?.anatomyId, examData, setAnotomyRef]);

  useEffect(() => {
    // Initialize tempCheckedSections from persisted selectedSections on first load
    setTempCheckedSections([...selectedSections]);
  }, []);

  useEffect(() => {
    // When the dropdown opens, sync temp state with persisted selectedSections
    if (templateSectionSelection) {
      setTempCheckedSections([...selectedSections]);
    }
  }, [templateSectionSelection]);

  /**
   * Fetches report template headers from the API based on the `examId`,
   * updates local component state and Zustand store accordingly.
   * 
   * Behavior:
   * - Retrieves report headers using `apiService.getById`.
   * - Updates both local state (`setReportTemplateHeaders`) and
   *   shared state in Zustand (`setReportTemplateHeadersInStore`).
   * - If there are pre-selected sections in the store, it uses them.
   * - Otherwise, it defaults to using all headers from the API.
   * - Calls `updateFilledFields` to refresh any dependent UI or logic.
   * 
   * @returns {Promise<void>} A promise that resolves when headers are fetched and state is updated.
   * @throws Will log an error to the console if the API call fails.
   */
  const fetchReportTemplateHeaders = async (): Promise<void> => {
    try {
      const reportResponse = await apiService.getById<any>(
        `${API_LIST.GET_REPORT_TEMPLATE_HEADERS}/${examId}`, 1
      );

      const data: { headerId: number; header: string }[] = reportResponse.data as { headerId: number; header: string }[];
      setReportTemplateHeaders(data);
      setReportTemplateHeadersInStore(data);

      if (selectedSections?.length > 0) {
        // Use what's already in Zustand
        setTempCheckedSections(selectedSections);
      } else {
        // First-time load or no stored state — use API data
        const headers = data?.map(item => item?.headerId) ?? [];
        setSelectedSections(headers);
        setTempCheckedSections(headers);
      }

      updateFilledFields();

    } catch (error) {
      console.error("Error fetching report template headers:", error);
    }
  }

  const handleAnotomyClick = (anatomyId: number) => {
    scrollToSection(anatomyId, headerOffset);
    setAnotomyRef(anatomyId);
  };

  const scroll = (direction: "left" | "right"): void => {
    if (!anatomyScrollRef.current) return;

    const anatomyButtons = Array.from(anatomyScrollRef.current.querySelectorAll('[data-anatomy-id]'));
    const currentIndex = anatomyButtons.findIndex(
      button => Number(button.getAttribute('data-anatomy-id')) === activeAnotomyReferences?.anatomyId
    );

    // Check if we can scroll in the requested direction
    if (direction === "left" && currentIndex <= 0) return;
    if (direction === "right" && currentIndex >= anatomyButtons.length - 1) return;

    const scrollContainer: HTMLElement | null = anatomyScrollRef.current.querySelector('[data-radix-scroll-area-viewport]');
    if (!scrollContainer) return;

    const scrollAmount: number = 300;
    const currentScroll: number = scrollContainer.scrollLeft;
    const newScroll: number = direction === "left"
      ? Math.max(0, currentScroll - scrollAmount)
      : currentScroll + scrollAmount;

    scrollContainer.scrollTo({
      left: newScroll,
      behavior: "smooth"
    });

    // Update the selected anatomy
    const newIndex = direction === "left" ? currentIndex - 1 : currentIndex + 1;
    const newAnatomyId = Number(anatomyButtons[newIndex].getAttribute('data-anatomy-id'));
    setAnotomyRef(newAnatomyId);
  };

  const handleImageClick = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    fetchCollaboratorsDetails(Number(examId));
  }, [examId]);


  const fetchCollaboratorsDetails = async (examId: number) => {
    try {
      const response = await apiService.get<any>(`${API_LIST.GET_EXAM_STATUS_AND_COLLABORATION_DETAILS}/${examId}`);
      setCollaboratorsDetails(response.data as ICollaboratorsDetails);
    } catch (error) {
      console.error('Error fetching collaborators details:', error);
    }
  }

  // Toggle selection only in temporary state
  const handleToggleHeader = (headerId: number) => {
    setTempCheckedSections((prev) =>
      prev.includes(headerId)
        ? prev.filter((h) => h !== headerId)
        : [...prev, headerId]
    );
  };

  /**
   * Confirms the selection of report template sections from a modal dialog.
   * 
   * Actions performed:
   * - Updates the selected sections with the current temporary selection.
   * - Closes the modal.
   * - Updates the UI state to reflect that template section selection is done.
   * - Triggers `updateFilledFields` to refresh dependent UI/data.
   * - Broadcasts the confirmed update to other browser tabs via the `reportTemplateChannel`.
   * 
   * @returns {void}
   */
  const reportTemplateModalConform = (): void => {
    setSelectedSections(tempCheckedSections);
    closeModal();
    setTemplateSectionSelection(false);
    updateFilledFields();

    // Broadcast the confirmed update to other tabs
    reportTemplateChannel?.postMessage({
      type: 'CONFIRM_TEMPLATE_UPDATE',
      payload: tempCheckedSections,
      origin: tabId,
    });
  };

  /**
   * Syncs selected report template sections across browser tabs using the `reportTemplateChannel`.
   * 
   * - Listens for `'CONFIRM_TEMPLATE_UPDATE'` messages from other tabs.
   * - If the message is from a different tab, updates both `selectedSections` and `tempCheckedSections` accordingly.
   * - Calls `updateFilledFields()` to refresh the dependent UI or logic.
   * - Cleans up the event listener on unmount.
   * 
   * @dependency [] This `useEffect` runs only once on component mount.
   */
  useEffect(() => {
    if (!reportTemplateChannel) return;

    const handleMessage = (event: MessageEvent) => {
      const { type, payload } = event.data || {};
      if (type === 'CONFIRM_TEMPLATE_UPDATE' && Array.isArray(payload)) {
        if (origin === tabId) return;
        setSelectedSections(payload);
        setTempCheckedSections(payload); // sync temp state too if dropdown is opened
        updateFilledFields();
      }
    };

    reportTemplateChannel.addEventListener('message', handleMessage);
    return () => {
      reportTemplateChannel.removeEventListener('message', handleMessage);
    };
  }, []);


  const currentStatus = collaboratorsDetails?.statusName ?? '';
  const statusColors = getStatusColors(currentStatus);

  return (
    <>
      {/* Report Template change confirmation modal */}
      <Modal isOpen={isOpen} onClose={closeModal} modalHeader modalHeaderClass='justify-center' modalFooter={false} modalTitle size="max-w-md" modalMainClass="items-center" modalContentClass="max-h-[90vh] overflow-y-auto scrollBar"
        modalTitleContent={<span className='text-[#3C3E43] text-base font-bold'>Edit Report Template</span>}>
        <div className="px-4 pb-6 mt-8">
          <div>The sections you're removing contain associated data and will be excluded from the report.</div>
          <br />
          <div>Do you want to proceed?</div>
          <div className='flex justify-end gap-4 mt-[40px]'>
            <Button onClick={() => closeModal()} className='py-[7px] px-[28.5px] text-[#242528] text-sm font-medium rounded-[4px] border border-[#242528]'>Cancel</Button>
            <Button onClick={() => reportTemplateModalConform()} className='px-4 py-[7px] text-white text-sm font-medium bg-[#1454CC] rounded-[4px]'>Proceed</Button>
          </div>
        </div>
      </Modal>

      <div className="relative">
        <div className="flex items-center gap-2 h-14 rounded-lg">
          <div className="flex items-center w-[12%]">
            <button
              className={`flex justify-center items-center w-46px h-46px`}
              onClick={handleImageClick}
              onKeyDown={(e) => e.key === 'Enter' && handleImageClick()}
              aria-label="Toggle collaborators"
            >
              <Collaborators
                className={`py-1 px-1.5 m-3 rounded-md mt-2 shadow-[2px_2px_5px_0px_rgba(0,0,0,0.25)]  border  border-anotomy-collaboratorsIcon stroke-${showDropdown ? statusColors.border : 'status-border-preliminary'}`}
              />

            </button>
            <div className="h-10 w-[1px] mr-2 bg-header-borderLine" />
            <div className="flex flex-col">
              <span className="text-sm text-border dark:text-user-cardLabel">Exam Type</span>
              <span className="text-xs font-semi-bold text-header-patientNameValue truncate underline">
                <button className={`w-full text-left text-base font-medium text-builder-darkText dark:text-dropdown-closeIcon underline ${templateSectionSelection ? 'underline' : ''}`} onClick={() => setTemplateSectionSelection(!templateSectionSelection)}>NT Scan</button>
              </span>
            </div>
            <div ref={templateHeaderDropdown} className={`bg-white shadow-[0px_2px_8px_0px_#00000040] rounded-[8px] w-[230px] h-[490px] ${templateSectionSelection ? 'absolute' : 'hidden'} top-[3rem] left-[3rem] mt-1 z-50`}>
              <div>
                <div className="p-3 flex justify-between border-b border-[#D9E5F2]">
                  <p className='text-[#1E1E1E] text-base font-medium'>NT Scan Template</p>
                  <button onClick={() => setTemplateSectionSelection(false)}><Close width='16' height='16' /></button>
                </div>
                <div className='h-[400px] overflow-y-auto scrollBar'>
                  {reportTemplateHeaders.length > 0 ?
                    reportTemplateHeaders.map((data) => (
                      <div id={`${data.headerId}`} key={data.headerId} className="p-3 border-b border-[#D9E5F2] last:border-none flex gap-3 items-center">
                        <Input checked={tempCheckedSections.includes(data.headerId)} onChange={() => handleToggleHeader(data.headerId)} type="checkbox" id={data.header} className='p-[10px]' />
                        <label htmlFor={data.header} className='text-[#5C5F66] text-base font-normal'>{data.header}</label>
                      </div>
                    ))
                    :
                    <div className="p-3 flex gap-3 items-center">
                      <Input type="checkbox" id='no_data' disabled className='h-auto p-[10px]' />
                      <label htmlFor='no_data' className='text-builder-lightText text-base font-normal'>No Data</label>
                    </div>
                  }
                </div>
                <div className='py-2 px-[10px] h-full flex gap-3 justify-end items-center'>
                  <button onClick={openModal} className='text-[#1454CC] text-lg font-normal'>Done</button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center gap-6 min-w-0">
            <Button
              variant="ghost"
              size="icon"
              onClick={() => !canPrevious ? scroll("left") : undefined}
              className={`flex-none text-header-icons p-1 hover:bg-gray-100 rounded-full transition-colors w-8 h-8 ${canPrevious && 'opacity-50 !cursor-not-allowed hover:bg-transparent'
                }`}
            >
              <CaretRight className="h-4 w-4 rotate-180 stroke-user-cardLabel" />
            </Button>
            <ScrollArea ref={anatomyScrollRef} className="flex-1 min-w-0">
              <div ref={anatomyScrollRef} className="flex gap-4">
                {examData?.map(({ anatomyId, anatomyName, anatomystatus }) => (
                  <AnatomyButton
                    key={anatomyId}
                    data-anatomy-id={anatomyId}
                    name={anatomyName}
                    isVerified={anatomystatus?.toLowerCase() === EAnatomyStatus.OPTIMAL.toLowerCase()}
                    isSelected={anatomyId === activeAnotomyReferences?.anatomyId}
                    isAutoCorelation={isScrollSyncEnabled && (anatomyId == activeScrolledSectionId)}
                    isScrollSyncEnabled={isScrollSyncEnabled}
                    onClick={() => handleAnotomyClick(anatomyId)}
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" className="hidden" />
            </ScrollArea>
            <Button
              variant="ghost"
              size="icon"
              onClick={() => !canNext ? scroll("right") : undefined}
              className={`flex-none text-header-icons p-1 hover:bg-gray-100 rounded-full transition-colors w-8 h-8 ${canNext && 'opacity-50 !cursor-not-allowed hover:bg-transparent'
                }`}
            >
              <CaretRight className="h-4 w-4 stroke-user-cardLabel" />
            </Button>
          </div>
        </div>
        {showDropdown && collaboratorsDetails && (
          <ExamCollaboratorsDropdown onClose={() => setShowDropdown(false)} options={collaboratorsDetails} />
        )}
      </div>
    </>
  );
};

export default AnotomyPanel;