"use client"
import type React from "react"
import { useAuth } from "@/context/auth-provider"
import { usePathname, useRouter } from "next/navigation"
import Sidebar from "./sidebar"
import { Loader2 } from "lucide-react"
import { useEffect } from "react"
import Header from "./header"
import AnotomyLayout from "./anotomy-layout"

interface ProtectedLayoutProps {
  readonly children: React.ReactNode
}
const ProtectedLayout: React.FC<ProtectedLayoutProps> = ({ children }) => {
  const { user, isLoading } = useAuth()
  const router = useRouter()
  const pathname: string | null = usePathname() ?? '';
  
  const showAnotomy: RegExpMatchArray | null = RegExp(/^\/(?:report-builder|gallery|report-section)\/[^/]+$/).exec(pathname);
  const isDashboard: boolean = pathname === '/dashboard';
  const isPatientProfile: boolean = pathname?.startsWith('/patient-profile') ?? false;

  useEffect(() => {
    if (!isLoading && !user) {
      router.push("/sign-in")
    }
  }, [user, isLoading, router])
  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin" />
      </div>
    )
  }
  if (!user) {
    return null
  }
  
  return (
    <div 
       className={`${
        !isDashboard && !isPatientProfile  ? "bg-builder-innerBackground" : "bg-builder-background"
      } flex h-screen`}
    >
      <Sidebar />
      <div className={`flex-1 flex flex-col gap-y-1 min-w-0 m-1`}>
        <Header />
        <div
          className={`${
            showAnotomy ? "border border-header-borderLine p-1 h-[92%]" : ""
          } rounded-lg flex flex-col gap-y-2 bg-builder-background`}
        >          {showAnotomy && <AnotomyLayout />}
          <main className="flex-1 overflow-y-auto">
            {children}
          </main>
        </div>
      </div>
    </div>
  )
}

export default ProtectedLayout