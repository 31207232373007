"use client"
import { FC, useEffect, useState } from 'react'
import { HeaderData, headerData } from '@/data/examDetails'
import { useParams, usePathname, useRouter } from 'next/navigation'
import { useAuth } from '@/context/auth-provider'
import CaretDown from '@/components/svg/caret-down'
import CaretLeft from '@/components/svg/caret-left'
import Image from 'next/image'
import { useTheme } from 'next-themes'
import SplitScreen from '../../../public/images/split-screen.svg'
import SplitScreenDark from '../../../public/images/split-screen-dark.svg'
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area'
import NotificationsDialog from './notifications';
import UserProfileDropdown from '@/components/layout/user-dropdown'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { useSplitScreen } from '@/context/split-screen-context'
import LogoutPopup from './logout-popup'
import { useExamStore } from '@/store/use-exam-store'
import ChatDialog from '../chat/chat-dialog'
import { momentFormat } from '@/utlity/format'

interface CustomHeaderProps {
  data?: HeaderData
}

const getPageTitle = (path: string): string => {
  const basePath = path.split('/')[1]; // Extracts the first segment after '/'
  
  const titles: Record<string, string> = {
    'dashboard': 'Dashboard',
    'report-builder': 'Report Builder',
    'gallery': 'Gallery',
    'patient-profile': 'Patient Profile',
    'report-section': 'Report Builder',
    'settings': 'Settings',
  };

  return titles[basePath] || 'none';
};

const CustomHeader: FC<CustomHeaderProps> = ({ data = headerData }) => {
  const [activeIcon, setActiveIcon] = useState<string | null>(null)
  const pathname: string | null = usePathname() ?? 'Report Builder';
  const { user } = useAuth();
  const params = useParams();
  const { examId } = params || {};
  const { theme } = useTheme();
  const router = useRouter();
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false)
  const { isSplitScreen, toggleSplitScreen, closeSplitScreen } = useSplitScreen()
  const [isLogoutPopupOpen, setIsLogoutPopupOpen] = useState(false)
  const { patientDetails } = useExamStore();
  const { patientName = "", patientId = "", accessionNumber = "", lmp = undefined, ga = "", edd = "" } = patientDetails || {};

  const isDashboard: boolean = pathname?.includes('/dashboard')
  const isGallery: boolean = pathname?.includes('/gallery')
  const isPatientProfile: boolean = pathname?.includes('/patient-profile')
  const isSettingsPage: boolean = pathname?.includes('/settings') ?? false;

  const handleNotificationsOpen = (open: boolean) => {
    setIsNotificationsOpen(open);
    setActiveIcon(open ? 'notifications' : null);
    if (open) {
      setIsChatOpen(false);
    }
  };

  const handleChatOpen = (open: boolean) => {
    setIsChatOpen(open);
    setActiveIcon(open ? 'chat' : null);
    if (open) {
      setIsNotificationsOpen(false);
    }
  };


  const handleIconClick = (iconName: string) => {
    if (activeIcon === iconName) {
      setActiveIcon(null)
    } else {
      setActiveIcon(iconName)
    }
    if (isDashboard && iconName === 'back') {
      setIsLogoutPopupOpen(true)
    } else if (iconName === 'back') {
      router.back()
    }
  }

  useEffect(() => {
    if (isDashboard) {
      closeSplitScreen();
    }
  }, [isDashboard, closeSplitScreen]);

  return (
    <>
      <header className="flex items-center justify-between px-2 sm:px-4 bg-header-background border border-header-borderLine rounded-lg shadow-sm">
        <div className="flex items-center justify-between flex-nowrap w-full min-h-[58px]">

        {/* Conditional Rendering for Settings Header */}
        {isSettingsPage ? (
          <div className="flex items-center p-4">
            <button
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Go back"
              onClick={() => router.back()}
            >
              <CaretLeft className="w-5 h-5" />
            </button>
            <span className="text-xl font-semibold text-header-title ml-3 truncate">
             {getPageTitle(pathname)}
            </span>
          </div>
        ) : (
          <>
            {/* Title Section - Align to the left */}
            <div className="flex items-center mr-4 sm:mr-8 flex-shrink-0">
              <button
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                aria-label="Go back"
                onClick={() => handleIconClick('back')}
              >
                <CaretLeft className="w-5 h-5" />
              </button>
              <span className="text-xl font-semibold text-header-title ml-3 truncate">
              {getPageTitle(pathname)}
              </span>
            </div>

            {/* Patient Info Grid - Scrollable container */}
            {!isDashboard && (
              <ScrollArea>
                <div className="flex gap-20 overflow-x-auto flex-nowrap p-2 ">
                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      Patient Name
                    </span>
                    <span className="text-sm text-header-patientNameValue mt-0.5 block truncate font-roboto-medium">
                      {patientName}
                    </span>
                  </div>

                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      Patient ID
                    </span>
                    <span className="text-sm text-header-patientNameValue mt-0.5 block truncate">
                      {patientId}
                    </span>
                  </div>

                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      Accession Number
                    </span>
                    <span className="text-sm text-header-patientNameValue mt-0.5 block truncate uppercase">
                      {accessionNumber}
                    </span>
                  </div>

                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      LMP
                    </span>
                    <span className="text-xs text-header-patientNameValue mt-0.5 block truncate">
                      {lmp ? momentFormat(lmp, 'MM/DD/YYYY') : "-"}
                    </span>
                  </div>

                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      GA
                    </span>
                    <span className="text-sm text-header-patientNameValue mt-0.5 block truncate">
                      {ga}
                    </span>
                  </div>

                  <div className="flex-shrink-0">
                    <span className="text-xs text-header-patientNameKey font-normal leading-none whitespace-nowrap">
                      EDD
                    </span>
                    <span className="text-sm text-header-patientNameValue mt-0.5 block truncate">
                      {edd}
                    </span>
                  </div>
                </div>
                <ScrollBar orientation="horizontal" className="hidden" />
              </ScrollArea>
            )}
          </>
        )}
        {/* Right Section - Icons and Profile */}
        <div className="flex items-center space-x-2 sm:space-x-2.5 flex-shrink-0 ml-4">
          {(isDashboard || isGallery || isPatientProfile) && (
            <div className="h-10 w-[1px] bg-header-borderLine hidden sm:block bg-[#eef0f2]" />
          )}

          {!isGallery && !isPatientProfile && !isDashboard && !isSettingsPage && (
            <>
              <button
                className={`p-2 rounded-lg transition-colors ${isSplitScreen ? 'border border-icon-activeBorder bg-icon-activeBg' : 'border border-transparent'}`}
                aria-label="Split screen"
                onClick={() => toggleSplitScreen(Number(examId))}
              >
                <Image
                  src={theme === 'dark' ? SplitScreenDark : SplitScreen}
                  alt="Split Screen"
                  width={24}
                  height={30}
                />
              </button>
              <div className="h-10 w-[1px] bg-header-borderLine hidden sm:block bg-[#eef0f2]" />
            </>
          )}
  
         {!isSettingsPage && (
          <NotificationsDialog 
            open={isNotificationsOpen} 
            onOpenChange={handleNotificationsOpen} 
            activeIcon={activeIcon} 
            setActiveIcon={setActiveIcon}
          />
         )}

          {!isDashboard && !isSettingsPage && (
           <ChatDialog 
             open={isChatOpen} 
             onOpenChange={handleChatOpen}
             activeIcon={activeIcon}
             setActiveIcon={setActiveIcon}
           />            
          )}
          {!isSettingsPage && (
          <div>
          <div className="h-10 w-[1px] bg-header-borderLine hidden sm:block bg-[#eef0f2]" />
          </div>
           )}
           </div>
        </div>

      {/* Right Section - Profile Icon */}
      <div className="flex items-center space-x-2 sm:space-x-2.5 flex-shrink-0 ml-4">
        <Popover open={showProfileDropdown} onOpenChange={setShowProfileDropdown}>
          <PopoverTrigger asChild>
          <div className="relative">
            <button 
              className="flex items-center bg-header-profile-container rounded-lg p-1 px-2 border border-header-borderLine"
            >
              <div className="w-8 h-8 bg-header-profile rounded-full flex items-center justify-center shrink-0">
                <span className="text-user-iconText text-xs font-bold uppercase">
                  {user?.firstName?.charAt(0)}
                </span>
              </div>
              <CaretDown 
                className={`w-5 h-5 ml-2 transition-transform ${showProfileDropdown ? 'rotate-180' : ''}`}  
              />
            </button>
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-[200px] m-2 p-0 rounded-t-lg border border-header-borderLine bg-user-userInfo ">
            <UserProfileDropdown onClose={() => setShowProfileDropdown(false)} />
          </PopoverContent>
        </Popover>
      </div>
    </header>
    {/* Render LogoutPopup if it's open */}
    {isLogoutPopupOpen && (
      <LogoutPopup onClose={() => setIsLogoutPopupOpen(false)} />
    )}
    </>
  )
}

export default CustomHeader