export enum HeaderIcons {
  Notifications = 'notifications',
  Chat = 'chat',
}

export interface ChatMessage {
  id: number;
  label?: string | null;
  content?: string | null;
  createdAt: string;
  isResolved?: boolean;
  isSender?: boolean;
  commentContent?: string | null;
  coordinates?: string | null;
  commentId?: number;
  frameId?: number;
  diaSeqId?: number;
  frameType?: number;
  diagnosticViewId?: number;
  anatomyId?: number;
  thumbnailId?: number;
}

export interface ChatDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  activeIcon: string | null;
  setActiveIcon: (icon: string | null) => void;
}

export interface PatientNotesResponse {
  id: number;
  value: string;
  createdAt: string;
  patientId: number;
}

export enum commentStatus {
  RESOLVED = "Resolve",
  UNRESOLVED = "Unresolve",
}