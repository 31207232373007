'use client';

import { useParams, useRouter, useSearchParams } from 'next/navigation';
import { Tooltip, TooltipTrigger, TooltipProvider } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { SidebarItemProps } from '@/types/layout';
import { Icons } from '../svg';
import { useExamStore } from '@/store/use-exam-store';
import { useSaveReportStore } from '@/store/use-save-report-store';
import { useReportStore } from '@/store/use-report-store';
import { ReportSection } from '@/types/report';
import { API_LIST } from '@/utlity/api-list';
import { apiService } from '@/utlity/api-service';
import Modal from '../ui/modal';
import { Button } from '../ui/button';
import { useState } from 'react';
import { useSaveReport } from '@/hooks/use-save-report';

const SidebarItem: React.FC<SidebarItemProps> = ({ className = '', route, isActive, href, onClick }) => {
  const router = useRouter();
  const IconComponent = Icons[route.iconName as keyof typeof Icons];
  const { clearSelections } = useExamStore();
  const { isSaved, saveReportState } = useSaveReportStore();
  const { saveReport } = useSaveReport();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pendingRoute, setPendingRoute] = useState<string | null>(null);
  const { examId } = useParams<{ examId: string }>() ?? {};
  const { setReport, setActiveReportSection, activeReportSection } = useReportStore();
  const searchParams = useSearchParams();

  const formActiveTab = searchParams?.get('formActiveTab') ?? null;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    clearSelections();

    if (route.path === '/dashboard' && !isSaved) {
      setIsModalOpen(true);
      setPendingRoute(href);
      return;
    }

    const pathList: string[] = ['/gallery', '/report-builder', '/patient-profile', 'report-section'];

    if (pathList.includes(route.path)) {
      const patientLastName: string | null = localStorage.getItem('patientName');
      const newTabName: string = encodeURIComponent(`${patientLastName}/${route.label}`);
      router.push(`${href}?tabTitle=${newTabName}`);
    } else {
      router.push(href);
    }
  };

  const handleDiscardAndExit = () => {
    setIsModalOpen(false);
    if (pendingRoute) {
      router.push(pendingRoute);
      setPendingRoute(null);
    }
  }


  const handleSaveAndContinue = async () => {
    await saveReport();
    fetchReportBuilder();
    setIsModalOpen(false);
    saveReportState();
  }

  /**
* Fetches the dynamic report form data from the API and updates the state.
*
* @returns {Promise<void>} A promise that resolves when the report data is successfully fetched and stored.
* @throws Will log the error if it occurs during the API call.
*/
  const fetchReportBuilder = async (): Promise<void> => {
    try {
      const reportResponse = await apiService.getById<any>(`${API_LIST.GET_DYNAMIC_FORM}/${examId}`, 1)
      const data = reportResponse.data as ReportSection[]

      setReport(data)
      setActiveReportSection(activeReportSection);
      if (formActiveTab) {
        setActiveReportSection(formActiveTab);
      }
    } catch {
    }
  }

  return (
    <>
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} modalHeader modalHeaderClass='justify-center' modalFooter={false} modalTitle size="max-w-md" modalMainClass='items-center' modalContentClass='max-h-[90vh] overflow-y-auto scrollBat'
      modalTitleContent={<span className='text-[#3C3E43] text-base font-bold'>Unsaved changes</span>}>
        <div className="px-4 pb-6 mt-8">
          <div>Your report has unsaved changes. Are you sure you want to proceed?</div>
          <div className="flex justify-end gap-4 mt-[40px]">
            <Button onClick={handleDiscardAndExit} className='py-[7px] px-[28.5px] text-[#242528] text-sm font-medium rounded-[4px] border border-[#242528]'>Discard & Exit</Button>
            <Button onClick={handleSaveAndContinue} className='px-4 py-[7px] text-white text-sm font-medium bg-[#1454CC] rounded-[4px]'>Save & Continue</Button>
          </div>
        </div>
      </Modal>

      <TooltipProvider key={route.path}>
        <Tooltip>
          <TooltipTrigger asChild>
            <button
              className={cn(
                "flex w-[51px] h-[54px] items-center justify-center rounded-lg",
                isActive ? "bg-card-active" : "",
                className
              )}
              onClick={handleClick}
            >
              <IconComponent
                className={cn(
                  isActive ? "stroke-card-filled" : "stroke-card-unfilled"
                )}
                stroke={isActive ? "card-filled" : "card-unfilled"}
              />
            </button>
          </TooltipTrigger>
        </Tooltip>
      </TooltipProvider>
    </>
  );
};

export default SidebarItem;