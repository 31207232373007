import React, { useState } from 'react';
import { useAuth } from '@/context/auth-provider';
import Setting from '../svg/setting';
import LogOut from '../svg/logout';
import LogoutPopup from './logout-popup';
import Link from 'next/link';

interface UserProfileDropdownProps {
  onClose: () => void;
}

const UserProfileDropdown: React.FC<UserProfileDropdownProps> = ({ onClose }) => {
  const { user } = useAuth();
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  return (
    <>
      {/* User Info Dropdown */}
        <div className="w-[198px] bg-user-userInfo rounded-t-lg flex flex-col items-center py-4">
          <div className="w-12 h-12 bg-header-profile rounded-full flex items-center justify-center mb-2">
            <span className="text-user-iconText text-base font-bold uppercase">
              {user?.firstName?.charAt(0)}
            </span>
          </div>
          <span className="text-sm font-bold text-user-text capitalize max-w-[150px] overflow-hidden text-ellipsis whitespace-nowrap" title={user?.userName}>
            {user?.firstName} {user?.lastName}
          </span>
        </div>

        {/* Menu Items */}
        <div className="w-[198px] bg-user-iconText rounded-b-md">
          <div className="flex flex-col">
            <Link 
              href="/settings/general"
              onClick={onClose}
              className="w-full px-4 py-2.5 text-sm text-user-dropText flex items-center bg-transparent rounded-lg mx-1 mt-1"
            >
              <Setting className="w-4 h-4 mr-3" />
              Settings
            </Link>

            <div className="h-px bg-header-borderLine mx-4" />

            <button 
              className="w-full px-4 py-2.5 text-sm text-user-dropText flex items-center bg-transparent rounded-lg mx-1 mb-1 "
              onClick={() => setShowLogoutPopup(true)}
            >
              <LogOut className="w-4 h-4 mr-3" />
              Log Out
            </button>
          </div>
        </div>

      {showLogoutPopup && <LogoutPopup onClose={() => setShowLogoutPopup(false)} />}
    </>
  );
};

export default UserProfileDropdown;